import { graphql } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import { Layout } from "../layouts/Layout"
import styled from "styled-components"

const Header = styled.div`
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const Title = styled.div`
  margin: 0px;
  padding: 0px;
  margin-top: 60px;
  margin-bottom: 20px;
  font-size: 2em;
`
const Url = styled.div`
  margin: 0px;
  padding: 0px;
  color: #6688cc;
  margin-bottom: 60px;
`
const Logos = styled.div`
  background: #ffffff;
  display: flex;
  flex-direction: row;
  margin-bottom: 80px;
`
const Html = styled.div`
  text-align: center;
`
const Image = styled(Img)`
  border-top: 1px solid #cccccc;
  z-index: 9999;
`


export default props => {
  // console.log(" @@@@ workTemplate.js /props", props);

  const title = props.data.markdownRemark.frontmatter.title;
  const image = props.data.markdownRemark.frontmatter.image_desktop.childImageSharp.fluid
  const navigation = props.location.state ? props.location.state.navigation : null
  const url = props.data.markdownRemark.frontmatter.link ? props.data.markdownRemark.frontmatter.link : ""
  const html = props.data.markdownRemark.html ? props.data.markdownRemark.html : "x"
  const icons = props.data.markdownRemark.frontmatter.icons ? props.data.markdownRemark.frontmatter.icons : ["linux", "mysql", "php", "htmlcssjs", "drupal"]
  const iconsHtml = icons.map(item => {
    return (
      <img
        key={item}
        height="36"
        src={"/logos/" + item + ".png"}
        style={{margin: "10px"}}
        alt={item}
      />
    )
  })

  // const { modal } = useModal()
  // console.log(" @@@@ workTemplate.js navigation", navigation);
  // console.log(" @@@@ workTemplate.js modal", modal);

  return (
    <Layout navigation={navigation}>
      <Header>
        <Title>{title}</Title>
        <Url href={url} target="_blank">{url}</Url>
        <Html dangerouslySetInnerHTML={{ __html: html }} />
        <Logos>{iconsHtml}</Logos>
      </Header>
      <Image fluid={image} />
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        slug
        title
        link
        image_desktop {
          childImageSharp {
            fluid( quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
